<template>
  <ckeditor
    :editor="editor"
    v-model="localValue"
    ref="valor"
    @input="updateValue()"
    :config="editorConfig"
    @ready="onEditorReady"
    
  ></ckeditor>
</template>

<script>
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";

//import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Strikethrough from "@ckeditor/ckeditor5-basic-styles/src/strikethrough";
import Underline from "@ckeditor/ckeditor5-basic-styles/src/underline";
import Subscript from "@ckeditor/ckeditor5-basic-styles/src/subscript";
import Superscript from "@ckeditor/ckeditor5-basic-styles/src/superscript";
import Code from "@ckeditor/ckeditor5-basic-styles/src/code";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
//automatic text transformation
import TextTransformation from "@ckeditor/ckeditor5-typing/src/texttransformation";
//Autoformatting
import Autoformat from "@ckeditor/ckeditor5-autoformat/src/autoformat";
//Block Indentacion
import Indent from "@ckeditor/ckeditor5-indent/src/indent";
import IndentBlock from "@ckeditor/ckeditor5-indent/src/indentblock";
// block-quote
import BlockQuote from "@ckeditor/ckeditor5-block-quote/src/blockquote";
//horizontal line
import HorizontalLine from "@ckeditor/ckeditor5-horizontal-line/src/horizontalline";
//Alignment
import Alignment from "@ckeditor/ckeditor5-alignment/src/alignment";
//Image
import EasyImage from "@ckeditor/ckeditor5-easy-image/src/easyimage";
import Image from "@ckeditor/ckeditor5-image/src/image";
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import ImageCaption from "@ckeditor/ckeditor5-image/src/imagecaption";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload";
import LinkImage from "@ckeditor/ckeditor5-link/src/linkimage";
import Base64UploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter";
import ImageResizeEditing from "@ckeditor/ckeditor5-image/src/imageresize/imageresizeediting";
import ImageResizeHandles from "@ckeditor/ckeditor5-image/src/imageresize/imageresizehandles";
import ImageResizeButtons from "@ckeditor/ckeditor5-image/src/imageresize/imageresizebuttons";
import CloudServices from "@ckeditor/ckeditor5-cloud-services/src/cloudservices";
import CKFinder from "@ckeditor/ckeditor5-ckfinder/src/ckfinder";
import Link from "@ckeditor/ckeditor5-link/src/link";
import UploadAdapter from "@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter";
import ListStyle from "@ckeditor/ckeditor5-list/src/liststyle";

//Html
import SourceEditing from '@ckeditor/ckeditor5-source-editing/src/sourceediting';
import HtmlComment from '@ckeditor/ckeditor5-html-support/src/htmlcomment';
import GeneralHtmlSupport from '@ckeditor/ckeditor5-html-support/src/generalhtmlsupport';
import HtmlEmbed from '@ckeditor/ckeditor5-html-embed/src/htmlembed';

//Video





//tabla
import Table from "@ckeditor/ckeditor5-table/src/table";
import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar";
import TableCaption from "@ckeditor/ckeditor5-table/src/tablecaption";

import TableProperties from "@ckeditor/ckeditor5-table/src/tableproperties";
import TableCellProperties from "@ckeditor/ckeditor5-table/src/tablecellproperties";

//media embed
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed';

export default {
  props: {
      value:{
          type: String,
          default: ''
      } 
  },
  components: {},
  data() {
    return {
      localValue: this.value,

      editor: ClassicEditor,

      editorConfig: {
        plugins: [
          Essentials,
          Bold,
          Italic,
          Strikethrough,
          Underline,
          //Subscript,
          //Superscript,
          //Code,
          Paragraph,
          Heading,
          TextTransformation,
          Autoformat,
          Indent,
          IndentBlock,
          BlockQuote,
          HorizontalLine,
          Alignment,
          EasyImage,
          Image,
          ImageToolbar,
          ImageCaption,
          ImageStyle,
          ImageResize,
          LinkImage,
          //Base64UploadAdapter,
          ImageUpload,
          ImageResizeEditing,
          ImageResizeHandles,
          ImageResizeButtons,
          //CKFinder,
          CloudServices,
          Link,
          UploadAdapter,
          ListStyle,
          Table,
          TableToolbar,
          TableCaption,
          TableProperties,
          TableCellProperties,
          MediaEmbed,
          SourceEditing,
          HtmlComment,
          GeneralHtmlSupport,
          HtmlEmbed,
          
        ],

        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "link",
            "strikethrough",
            "underline",
            //"subscript",
            //"superscript",
            //"code",
            "|",
            "outdent",
            "indent",
            "blockQuote",
            "horizontalLine",
            "alignment",
            "bulletedList",
            "numberedList",
            "|",
            //"mediaEmbed",
            //"uploadImage",
            "insertTable",
            "|",
            'undo',
            'redo',
            "|",
            'sourceEditing',  
            'htmlEmbed'

          ],
        },
          htmlSupport: {
            allow: [
                {
                    name: /.*/,
                    attributes: true,
                    classes: true,
                    styles: true
                }
            ]
        },
        htmlEmbed: {
            showPreviews: true,
            
        },
        image: {
          resizeUnit: "px",
          resizeOptions: [
            {
              name: "resizeImage:original",
              value: null,
              icon: "original",
            },
            {
              name: "resizeImage:100",
              value: "100",
              icon: "medium",
            },
            {
              name: "resizeImage:300",
              value: "300",
              icon: "large",
            },
          ],
          toolbar: [
            "resizeImage",
            "imageStyle:inline",
            "imageStyle:block",
            "imageStyle:side",
            "imageStyle:alignLeft",
            "imageStyle:alignRight",
            "imageStyle:alignBlockLeft",
            "imageStyle:alignBlockRight",
            "imageStyle:alignCenter",
            "toggleImageCaption",
          ],
        },
        table: {
          contentToolbar: [
            "tableColumn",
            "tableRow",
            "mergeTableCells",
            "tableProperties",
            "tableCellProperties",
            "toggleTableCaption",
          ],
        },
      },
      editorCk:[]
    };
  },

  computed: {},
  watch: {
    localValue (value) {
       this.$emit('input', value)
     },
     value (value) {
       this.localValue = value
     }
  },
  created() {
      
    //this.editor.ui.view.editable.element.style.minHeight = '500px'
  },
  setup() {},
  methods: {
    onEditorReady(editor){
      this.editorCk=editor
    },
    updateValue() {
      this.$emit('input',this.$refs.valor.value);
    }
  },
  mounted() {},
};
</script>

<style>
.ck-editor__editable_inline {
  min-height: 400px !important;
}
.ck.ck-editor__main .ck-content {
  min-height: 300px !important;
}
</style>