<template>
  <b-card class="blog-edit-wrapper" :title="formTitle">
    <!-- form -->
    <validation-observer ref="fiscaliaRules">
      <b-form class="mt-2">
        <b-row>
          <!-- <b-col md="6" class="mb-2">
              <div class="border rounded p-2">
                  <h4 class="mb-1 text-center">Foto Fiscal</h4>
                  <div class="text-center">
                      <b-img ref="refPreviewEl" :src="imagen" height="190" width="190" class="rounded mr-2 mb-1 mb-md-0" v-if="imagen" />
                      <b-img ref="refPreviewEl" :src="fiscalia.foto_fiscal" height="190" width="190" class="rounded mr-2 mb-1 mb-md-0" v-else />
                      <b-form-file ref="refInputEl" v-model="fiscaliaFile" accept=".jpg, .png, .gif" placeholder="Cambiar Foto" @input="inputImageRenderer" />
                  </div>
              </div>
          </b-col> -->
          <b-col cols="6">
            <div class="border rounded p-2 mb-2">
              <h4 class="text-center">
                <b>Foto del Fiscal</b>
              </h4>
              <div class="text-center">
                <b-form-group label="" label-for="">
                  <div class="box-imagen box-imagen1 border rounded">
                    <b-img
                      v-if="imagen"
                      :src="imagen"
                      center
                      fluid
                      thumbnail
                      class="rounded"
                      alt="Card image cap"
                    />
                  </div>
                </b-form-group>
                <b-button
                  v-b-modal.modal-seleccionar-imagen
                  variant="flat-dark"
                  size="sm"
                  @click="selectImgFiscal = true"
                >
                  <feather-icon icon="ImageIcon" class="mr-50" />
                  <span class="align-middle">Elegir Imagen</span>
                </b-button>
              </div>
            </div>
          </b-col>
          <b-col cols="6">
            <div class="border rounded p-2 mb-2">
              <h4 class="text-center">
                <b>Foto de la Fiscalía</b>
              </h4>
              <div class="text-center">
                <b-form-group label="" label-for="">
                  <div class="box-imagen box-imagen1 border rounded">
                    <b-img
                      v-if="imagen_fiscalia"
                      :src="imagen_fiscalia"
                      center
                      fluid
                      thumbnail
                      class="rounded"
                      alt="Card image cap"
                    />
                  </div>
                </b-form-group>
                <b-button
                  v-b-modal.modal-seleccionar-imagen
                  variant="flat-dark"
                  size="sm"
                  @click="selectImgFiscalia = true"
                >
                  <feather-icon icon="ImageIcon" class="mr-50" />
                  <span class="align-middle">Elegir Imagen</span>
                </b-button>
              </div>
            </div>
          </b-col>
          <b-col md="6">
            <b-form-group              
              label-for="nombre-fiscal"
              class="mb-2"
            >
              <label for="nombre-fiscal">Nombre Fiscal <span class="text-danger">(*)</span></label>
            
              <validation-provider
                #default="{ errors }"
                name="Nombre Fiscal"
                rules="required"
              >
                <b-form-input
                  id="nombre-fiscal"
                  v-model="fiscalia.nombre_fiscal"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.nombre_fiscal"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
                <!-- <small v-for="error in errores.nombre" :key="error" class="text-danger">{{ error }}</small> -->
              </validation-provider>
            </b-form-group>            
          </b-col>

          <b-col md="6">
            <b-form-group              
              label-for="cargo-fiscal"
              class="mb-2"
            >
              <label for="cargo-fiscal">Cargo</label>
            
              <validation-provider                
                name="Cargo"
                rules=""
              >
                <b-form-input
                  id="cargo-fiscal"
                  v-model="fiscalia.cargo"                  
                />
              </validation-provider>
            </b-form-group>            
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Nombre Fiscalia"
              label-for="nombre-fiscalia"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Nombre Fiscalia"
                rules="required"
              >
                <b-form-input
                  id="nombre-fiscalia"
                  v-model="fiscalia.nombre"
                  disabled
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.nombre"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Dirección" label-for="direccion" class="mb-2">
              <validation-provider
                #default="{ errors }"
                name="Direccion"
                rules=""
              >
                <b-form-input
                  id="direccion"
                  v-model="fiscalia.direccion"
                  :state="errors.length > 0 ? false : null"
                  disabled
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.direccion"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Teléfono" label-for="telefono" class="mb-2">
              <validation-provider
                #default="{ errors }"
                name="Telefono"
                rules=""
              >
                <b-form-input
                  id="telefono"
                  v-model="fiscalia.telefono"
                  disabled
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.telefono"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Fax" label-for="fax" class="mb-2">
              <validation-provider #default="{ errors }" name="Fax" rules="">
                <b-form-input
                  id="fax"
                  v-model="fiscalia.fax"
                  :state="errors.length > 0 ? false : null"
                  disabled
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.fax"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
          
          <b-col md="6">
            <b-form-group label="Ubicacion" label-for="ubicacion" class="mb-2">
              <validation-provider
                #default="{ errors }"
                name="Ubicacion"
                rules=""
              >
                <b-form-input
                  id="ubicacion"
                  v-model="fiscalia.ubicacion"
                  :state="errors.length > 0 ? false : null"
                  disabled
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.ubicacion"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
                    <b-col md=12>
                         <b-form-group
            label="Descripcion"
            
            >
                <CkEditorPersonalizado
                    ref="ckeditor"
                    v-model="fiscalia.descripcion"
                  ></CkEditorPersonalizado>

            </b-form-group>
          </b-col>  
          <b-col md="6">
            <b-form-group label="Latitud" label-for="latitud" class="mb-2">
              <validation-provider
                #default="{ errors }"
                name="Latitud"
                rules="required"
              >
                <b-form-input
                  id="latitud"
                  v-model="ubicacion.position.lat"
                  :state="errors.length > 0 ? false : null"
                  disabled
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.latitud"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Longitud" label-for="longitud" class="mb-2">
              <validation-provider
                #default="{ errors }"
                name="Longitud"
                rules="required"
              >
                <b-form-input
                  id="longitud"
                  v-model="ubicacion.position.lng"
                  :state="errors.length > 0 ? false : null"
                  disabled
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.longitud"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-checkbox
              v-model="selected"
              value="1"
              unchecked-value="0"
              id="asiento-fiscal"
              switch
              @change="traerFiscalias"
              ><b>Agregar como asiento Fiscal</b>
            </b-form-checkbox>
          </b-col>
          <b-col md="6" v-if="selected == '1'">
            <b-form-group
              label="Seleccione Fiscalia Principal"
              label-for="fiscalia-dependiente"
              class="mb-2"
            >
              <v-select
                id="fiscalia-dependiente"
                v-model="fiscalia.fiscaliaDependiente"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="fiscaliaOption"
                label="nombre"
              />
            </b-form-group>
                             
          </b-col>
          <b-col cols="12">
            <div>
              <l-map
                :zoom.sync="zoom"
                :options="mapOptions"
                :center="center"
                style="height: 500px; width: 100%"
              >
                <l-tile-layer :url="url" :attribution="attribution" />
                <l-marker
                  :visible="ubicacion.visible"
                  :draggable="ubicacion.draggable"
                  :lat-lng.sync="ubicacion.position"
                  @click="alert(ubicacion)"
                >
                  <l-popup content="hola" />
                </l-marker>
              </l-map>
            </div>
          </b-col>

          <b-col cols="12" class="mt-50">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="guardar"
            >
              Guardar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              :to="{ name: 'fiscalias' }"
            >
              Cancelar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <ImagenComponent @enviarImagen="recibirImagen"></ImagenComponent>    
    <b-row> </b-row>

    <!--/ form -->
  </b-card>
</template>

<script>
import axios from "@axios";
import router from "@/router";
import ImagenComponent from "@/componentes/ImagenComponent.vue";
import CkEditorPersonalizado from "@/componentes/CkEditorPersonalizado.vue";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BFormCheckbox,
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import {
  LMap,
  LTileLayer,
  LMarker,
  LPolyline,
  LLayerGroup,
  LTooltip,
  LPopup,
  LControlZoom,
  LControlAttribution,
  LControlScale,
  LControlLayers,
} from "vue2-leaflet";
import { latLngBounds, Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
  components: {
    BFormCheckbox,
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    LMap,
    LTileLayer,
    LMarker,
    LPolyline,
    LLayerGroup,
    LTooltip,
    LPopup,
    LControlZoom,
    LControlAttribution,
    LControlScale,
    LControlLayers,
    ValidationProvider,
    ValidationObserver,
    ImagenComponent,
    CkEditorPersonalizado,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,

      min,
      errores: [],

      fiscalia: {
        nombre_fiscal: "",
        nombre: "",
        cargo:"",
        direccion: "",
        telefono: "",
        fax: "",
        ubicacion: "",
        descripcion:"",
      },
      fiscaliaFile: null,
      imagenMiniatura: "",
      fiscaliaOption: [],

      ubicacion: {
        position: { lat: -19.062117883514652, lng: -65.25878906250001 },
        visible: true,
        draggable: true,
      },
      selected: "0",
      zoom: 13,
      center: [-19.062117883514652, -65.25878906250001],
      mapOptions: {
        zoomControl: true,
        attributionControl: true,
        zoomSnap: true,
      },
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      selectImgFiscal: false,
      selectImgFiscalia: false,
      imagen: "",
      imagen_fiscalia: "",
    };
  },
  created() {
    if (router.currentRoute.params.id) {
      axios
        .get(`/fiscalias/${router.currentRoute.params.id}`)
        .then((response) => {
          this.fiscalia = response.data.data;
          this.imagen = this.fiscalia.foto_fiscal;
          this.imagen_fiscalia = this.fiscalia.foto_fiscalia;
          this.ubicacion.position.lat = this.fiscalia.latitud;
          this.ubicacion.position.lng = this.fiscalia.longitud;
          this.center = [this.fiscalia.latitud, this.fiscalia.longitud];
          if (this.fiscalia.fiscalia_padre) {
            this.fiscalia.fiscaliaDependiente = this.fiscalia.fiscalia_padre;
            this.selected = "1";
            this.getAllFiscalias();
          }
        })
        .catch((error) => console.error(error));
    }
  },
  setup() {
    const refInputEl = ref(null);
    const refPreviewEl = ref(null);
    var imagen = ref("");

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        refPreviewEl.value.src = base64;
        imagen.value = base64;
      }
    );

    return {
      refInputEl,
      refPreviewEl,
      inputImageRenderer,
      imagen
    };
  },
  computed: {
    formTitle() {
      return this.fiscalia.id ? "Editar Fiscalía" : "Nueva Fiscalía";
    },
  },
  watch: {
    "fiscalia.nombre": function () {
      this.errores.nombre = [];
    },
    "fiscalia.nombre_fiscal": function () {
      this.errores.nombre_fiscal = [];
    },
    "fiscalia.cargo": function () {
      this.errores.cargo = [];
    },
    "fiscalia.direccion": function () {
      this.errores.direccion = [];
    },
    "fiscalia.telefono": function () {
      this.errores.telefono = [];
    },
    "fiscalia.fax": function () {
      this.errores.fax = [];
    },
    "fiscalia.ubicacion": function () {
      this.errores.ubicacion = [];
    },
    "ubicacion.position.lat": function () {
      this.errores.latitud = [];
    },
    "ubicacion.position.lng": function () {
      this.errores.longitud = [];
    },
  },

  methods: {
    recibirImagen(imagen) {
      if(this.selectImgFiscal){
        this.imagen = imagen.url;
        this.selectImgFiscal = false;
      }
      if(this.selectImgFiscalia){
        this.imagen_fiscalia = imagen.url;
        this.selectImgFiscalia = false;
      }      
    },    
    async success(Mensaje) {
      await this.$swal({
        title: Mensaje,

        icon: "success",
        timer: 2000,
        confirmButtonText: "Aceptar",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
      router.push({ name: "fiscalias" });
    },
    async getAllFiscalias() {
      await axios
        .get("/getAllFiscaliasSelect")
        .then((response) => {
          this.fiscaliaOption = response.data.data;
        })
        .catch((error) => reject(error));
    },

    traerFiscalias() {
      if (this.selected == "1") this.getAllFiscalias();
    },

    alert(item) {
      // eslint-disable-next-line no-alert
      alert(`this is ${JSON.stringify(item)}`);
    },
    validar() {
      this.$refs.fiscaliaRules.validate().then((success) => {
        if (success) {
          this.guardar();
        }
      });
    },
    async guardar() {
      const formData = new FormData();
      formData.append("nombre", this.fiscalia.nombre);
      formData.append("nombre_fiscal", this.fiscalia.nombre_fiscal);
      formData.append("cargo", this.fiscalia.cargo);
      formData.append("direccion", this.fiscalia.direccion);
      formData.append("telefono", this.fiscalia.telefono);
      formData.append("fax", this.fiscalia.fax);
      formData.append("ubicacion", this.fiscalia.ubicacion);
      formData.append("latitud", this.ubicacion.position.lat);
      formData.append("longitud", this.ubicacion.position.lng);
      formData.append("foto_fiscal", this.imagen);
      formData.append("foto_fiscalia", this.imagen_fiscalia);
      formData.append("descripcion",this.fiscalia.descripcion);

      if (this.selected == "1")
        formData.append(
          "fiscalia_padre_id",
          this.fiscalia.fiscaliaDependiente.id
        );
      if (this.fiscalia.id) {
        formData.append("_method", "PUT");
        axios
          .post(`/fiscalias/${this.fiscalia.id}`, formData)
          .then((response) => {
            this.success("Actualizado con Exito");
          })
          .catch((error) => {
            if (error.response.status === 422) {
              this.errores = error.response.data.errors;
            } else {
              console.error(error);
            }
          });
      } else {
        axios
          .post(`/fiscalias`, formData)
          .then((response) => {
            this.success("Insertado con Exito");
            //router.push({ name: 'fiscalias' });
          })
          .catch((error) => {
            if (error.response.status === 422) {
              this.errores = error.response.data.errors;
            } else {
              console.error(error);
            }
          });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
@import "@core/scss/vue/libs/map-leaflet.scss";
.custom-file-input:lang(es) ~ .custom-file-label::after {
  content: "Buscar";
}

.vue2leaflet-map {
  &.leaflet-container {
    height: 350px;
  }
}
.box-imagen {
  height: 13rem;
}
.box-imagen img {
  width: 100%;
  height: 100%;
}
.box-imagen1 img {
  object-fit: contain;
}
.vs__open-indicator {
  fill: rgba(60, 60, 60, 0.5);
}
</style>

